import React, { useState, useEffect, useContext } from 'react'
import Box from '@mui/material/Box'
import TextInput from 'src/components/Form/TextInput'
import Modal from 'src/components/Modals/Modal'
import FooterForm from 'src/components/Form/FooterForm'
import { AddEditProps } from 'src/types/pages.types'
import { useForm } from 'src/hooks/useForm'
import { AlertContext } from 'src/context/alert/alertContext'
import { AuthContext } from 'src/context/auth/authContext'
import { InfoContext } from 'src/context/info/infoContext'
import { labels } from 'src/labels/main_labels'
import {
  createBooking,
  updateBooking,
  getAllCampaignsByCompany,
  getAllIncidentsByCompany,
  sendNewBookingNotification,
  sendEditBookingNotification
} from 'src/services/bookings.services'
import { getAllStoresByCompany, updateStoreCalendar } from 'src/services/stores.services'
import { getAllUsersByCompanyAndStore } from 'src/services/users.services'
import SelectDialog from 'src/components/Form/SelectDialog'
import { SelectChangeEvent, Grid, Typography, useTheme } from '@mui/material'
import SimpleSelect from 'src/components/Form/Select'
import { GridFlexBetween, MainBox } from 'src/components/Form/form.styles'
import { getAllCampaignsByCompanyAndStore } from 'src/services/campaigns.services'
import { createIssue } from 'src/services/issues.services'
import { editBookingsSwalConfig } from 'src/utils/swal.utils'
import Swal from 'sweetalert2'
import { isBefore, isSameDay } from 'date-fns'

const INITIAL_VALUES = {
  bookingId: '',
  notes: ''
}

export default function BookingsAddEdit({ dataToEdit, closeModal, openModal, onDelete, fetchData }: AddEditProps): JSX.Element {
  const editing = !!dataToEdit
  const theme = useTheme()
  const { showSnackbar } = useContext(AlertContext)
  const { userData, firebase } = useContext(AuthContext)
  const { pathologies, allCompanies, fetchPathologies, allStores } = useContext(InfoContext)
  const { handleChange, values, resetForm } = useForm(INITIAL_VALUES, dataToEdit)
  const [loading, setLoading] = useState(false)
  const [companyUsers, setCompanyUsers] = useState([])
  const [companyIncidents, setCompanyIncidents] = useState([])
  const [companyCampaigns, setCompanyCampaigns] = useState([])
  const [patient, setPatient] = useState<any | never>(null)
  const [medicalHistory, setMedicalHistory] = useState<any | never>('')
  const [selectedDate, setSelectedDate] = useState<any | never>('')
  const [selectedSlot, setSelectedSlot] = useState<any | never>('')
  const [selectedCampaign, setSelectedCampaign] = useState<any | never>(null)
  const [selectedIncident, setSelectedIncident] = useState<any | never>(null)
  const [bookingReason, setBookingReason] = useState<any | never>(null)
  const [affectedZone, setAffectedZone] = useState<any | never>(null)
  const [availableSlots, setAvailableSlots] = useState([])
  const [allCompanyStores, setAllCompanyStores] = useState<any[]>([])
  const [company, setCompany] = useState<any | never>(null)
  const [store, setStore] = useState<any | never>(null)
  const [storeCalendar, setStoreCalendar] = useState<any | never>(null)

  const resetComponent = () => {
    setLoading(false)
    resetForm()
    setPatient(null)
    setSelectedCampaign(null)
    setSelectedIncident(null)
    setSelectedDate('')
    setSelectedSlot('')
    setMedicalHistory('')
    setAvailableSlots([])
    setCompanyCampaigns([])
    setBookingReason(null)
    setCompanyIncidents([])
    setCompanyUsers([])
    setCompany(null)
    setAllCompanyStores([])
    setStore(null)
    setAffectedZone(null)
  }

  const checkDate = (date: Date) => {
    const day = storeCalendar.filter(
      (book: any) => date.getDate() === book.day && date.getMonth() === book.month && date.getFullYear() === book.year
    )
    if (isBefore(new Date(), date) || isSameDay(new Date(), date)) {
      return Boolean(
        day
          ? !day.some(
              (elm: any) =>
                elm.reserved === false || new Date(elm.year, elm.month, elm.day).toLocaleDateString('fr-CA') === dataToEdit?.date
            )
          : !day
      )
    } else return true
  }

  const handleSlotsAvailables = () => {
    const date = new Date(selectedDate)
    const sameDayBookings = storeCalendar?.filter((book: any) => {
      return date.getDate() === book.day && date.getMonth() === book.month && date.getFullYear() === book.year && !book.reserved
    })
    const slotsAvailables = sameDayBookings.map(
      (book: any) => String(book.hour).padStart(2, '0') + ':' + String(book.minutes).padStart(2, '0')
    )

    if (dataToEdit && dataToEdit.date === new Date(selectedDate).toLocaleDateString('fr-CA')) {
      slotsAvailables.unshift(dataToEdit.hourFormatted)
    }

    setAvailableSlots(
      slotsAvailables.map((elm: string) => {
        return { value: elm, label: elm }
      })
    )
  }

  const getStoreCalendar = async (storeName: string, storeId: any) => {
    await firebase.db
      .collection('stores')
      .where('storeName', '==', storeName)
      .onSnapshot((querySnapshot: any) => {
        const store: any[] = []
        querySnapshot.forEach((doc: any) => {
          setStoreCalendar(doc.data().calendar)
        })
      })
    // if (store) setStoreCalendar(store.calendar)

    // if (allStores) {
    //   const store = allStores.find((store: any) => store.id === storeId)

    //   if (store) setStoreCalendar(store.calendar)
    // }
  }

  useEffect(() => {
    if (selectedDate && storeCalendar) {
      handleSlotsAvailables()
    }
  }, [selectedDate, storeCalendar])

  useEffect(() => {
    if (bookingReason) setPatient(null)
  }, [bookingReason])

  const handleChangeDate = (newValue: Date | null) => {
    setSelectedDate(newValue)

    if (availableSlots.length > 0) setSelectedSlot('')
  }

  const handleChangeSlot = (e: SelectChangeEvent<string>) => {
    setSelectedSlot(e.target.value)
  }

  useEffect(() => {
    if (dataToEdit) {
      if (dataToEdit.patient) setPatient(dataToEdit.patient)
      if (dataToEdit.date) setSelectedDate(dataToEdit.date)
      if (dataToEdit.hourFormatted) setSelectedSlot(dataToEdit.hourFormatted)
      if (dataToEdit.bookingReason) setBookingReason(dataToEdit.bookingReason)
      if (dataToEdit.bookingReason?.id === labels.es.comunPatology) setMedicalHistory(dataToEdit.patient?.all?.medicalHistory || '')
      if (dataToEdit.campaign) setSelectedCampaign(dataToEdit.campaign)
      if (dataToEdit.incident) setSelectedIncident(dataToEdit.incident)
      if (dataToEdit.affectedZone) setAffectedZone(dataToEdit.affectedZone)
      if (dataToEdit.company) setCompany(dataToEdit.company)
      if (dataToEdit.store) setStore(dataToEdit.store)
    }

    return () => resetComponent()
  }, [dataToEdit])

  const loadUsers = async () => {
    if (userData && userData.companyId && userData.storeId) {
      const users = await getAllUsersByCompanyAndStore(userData.companyId, userData.storeId)

      if (users && users.length > 0) setCompanyUsers(users)
    }
  }

  const loadAdminUsers = async () => {
    if (userData && userData.role.id === 'role1' && store && company) {
      const users = await getAllUsersByCompanyAndStore(company.id, store.id)

      if (users && users.length) {
        setCompanyUsers(users)
        if (!dataToEdit) setPatient(null)
      } else {
        if (!dataToEdit) setPatient(null)

        setCompanyUsers([])
      }
    }
    if (userData && userData.role.id === 'role3' && store) {
      const users = await getAllUsersByCompanyAndStore(userData.companyId, store.id)
      if (users && users.length) {
        setCompanyUsers(users)
        if (!dataToEdit) setPatient(null)
      } else {
        if (!dataToEdit) setPatient(null)

        setCompanyUsers([])
      }
    }
  }

  const loadPhysioData = async () => {
    if (userData && userData.role.id === 'role2' && store) {
      const storeInfo = allStores.find((storeInfo) => storeInfo.id === store.id)

      if (storeInfo?.companyId) {
        setCompany(storeInfo.company)
        const users = await getAllUsersByCompanyAndStore(storeInfo.companyId, store.id)
        fetchPathologies(storeInfo.companyId)

        const campaigns = await getAllCampaignsByCompanyAndStore(storeInfo.companyId, store.id)
        if (campaigns && campaigns.length) {
          setCompanyCampaigns(campaigns)
        }
        if (users && users.length) {
          setCompanyUsers(users)

          if (!dataToEdit) setPatient(null)
        } else {
          if (!dataToEdit) setPatient(null)

          setCompanyUsers([])
        }
      }
    }
  }

  const loadCampaigns = async () => {
    if (userData && userData.companyId && userData.storeId) {
      const campaigns = await getAllCampaignsByCompanyAndStore(userData.companyId, userData.storeId)
      if (campaigns && campaigns.length) {
        setCompanyCampaigns(campaigns)
      }
    }
  }

  const loadIncidents = async () => {
    if (userData && userData.companyId && userData.storeId) {
      const incidents = await getAllIncidentsByCompany(userData.companyId)
      if (incidents && incidents.length) {
        const filterByUser = incidents.filter((incident: any) => {
          return incident.injuredWorker.id === patient.id && !incident.closed
        })
        setCompanyIncidents(filterByUser)
      }
    }
  }

  const loadAdminCampaingns = async () => {
    if (userData && userData.role.id === 'role1') {
      const campaigns = await getAllCampaignsByCompany(company.id)
      if (campaigns && campaigns.length) {
        setCompanyCampaigns(campaigns.filter((elm: any) => !elm.deleted && elm.storeId === store.id))
      }
    } else if (userData && userData.role.id === 'role3') {
      const campaigns = await getAllCampaignsByCompany(userData.companyId)
      if (campaigns && campaigns.length) {
        setCompanyCampaigns(campaigns.filter((elm: any) => !elm.deleted && elm.storeId === store.id))
      }
    }
  }

  const loadAdminIncidents = async () => {
    if (!company && userData.role.id === 'role1') return
    if (userData && userData.role.id === 'role1') {
      const incidents = await getAllIncidentsByCompany(company.id)
      if (incidents && incidents.length) {
        const filterByUser = incidents.filter((incident: any) => incident.injuredWorker?.id === patient.id && !incident.closed)
        setCompanyIncidents(filterByUser)
      }
    } else if (userData && userData.role.id === 'role3') {
      const incidents = await getAllIncidentsByCompany(userData.companyId)
      if (incidents && incidents.length) {
        const filterByUser = incidents.filter((incident: any) => incident.injuredWorker?.id === patient.id)

        setCompanyIncidents(filterByUser)
      }
    }
  }

  const loadMedicalHistory = async () => {
    if (patient.all.medicalHistory) setMedicalHistory(patient.all.medicalHistory)
  }

  useEffect(() => {
    if (userData) {
      loadUsers()
      loadCampaigns()
    }
  }, [userData, openModal])

  useEffect(() => {
    if (!dataToEdit) setSelectedIncident(null)
    if (patient) loadIncidents()
    if (patient) loadAdminIncidents()
    if (patient) loadMedicalHistory()
  }, [patient])

  useEffect(() => {
    if (store && userData.role.id === 'role1') {
      loadAdminUsers()
      loadAdminCampaingns()
      fetchPathologies(company.id)
    } else if (store && userData.role.id === 'role2') {
      loadPhysioData()
      // loadPhysioCampaingns()
      // fetchPathologies(userData.companyId)
    } else if (store && userData.role.id === 'role3') {
      loadAdminUsers()
      loadAdminCampaingns()
      fetchPathologies(userData.companyId)
    }

    if (store) getStoreCalendar(store?.name, store?.id)
    if (userData?.store?.id) getStoreCalendar(userData?.store?.name, userData?.store?.id)
  }, [store, userData])

  const updatedCalendar = () => {
    const updateCalendar = storeCalendar.map((book: any) => {
      const dateFromDataBase = new Date(book.year, book.month, book.day).toLocaleDateString('fr-CA')
      const hourFromDataBase = `${String(book.hour).padStart(2, '0')}:${String(book.minutes).padStart(2, '0')}`
      if (selectedDate === dateFromDataBase && selectedSlot === hourFromDataBase) {
        book.reserved = true
      }
      if (dataToEdit?.hourFormatted === hourFromDataBase && dataToEdit.date === dateFromDataBase) {
        book.reserved = true
      }

      if (hourFromDataBase === dataToEdit?.hourFormatted && dateFromDataBase === dataToEdit.date) {
        if (hourFromDataBase !== selectedSlot || dateFromDataBase !== selectedDate) book.reserved = false
      }
      return book
    })
    return updateCalendar
  }

  const finishSaving = async () => {
    resetComponent()
    if (fetchData) fetchData()
    closeModal()
    setLoading(false)
    showSnackbar('success', labels.es.bookingUpdated)
  }

  const onSave = async () => {
    setLoading(true)
    const newBooking = JSON.parse(JSON.stringify(values))
    newBooking.updatedAt = Date.now()
    newBooking.patient = patient
    newBooking.completed = false
    newBooking.date = selectedDate
    newBooking.year = new Date(selectedDate).getFullYear()
    newBooking.month = new Date(selectedDate).getMonth()
    newBooking.day = new Date(selectedDate).getDate()
    newBooking.hourFormatted = selectedSlot
    newBooking.hour = selectedSlot.substring(0, 2)
    newBooking.minutes = selectedSlot.substring(3, 5)
    newBooking.bookingReason = bookingReason || null
    newBooking.campaign = selectedCampaign || null
    newBooking.incident = selectedIncident || null
    if (selectedIncident) {
      if (selectedIncident.all.sessions) {
        const totalSessions = selectedIncident.all.sessions + 1

        if (totalSessions === 8) {
          newBooking.incident.all.totalSessions = totalSessions
          await firebase.db
            .collection('incidents')
            .doc(selectedIncident.id)
            .update({
              sessions: totalSessions,
              closed: { closedBy: 'system', closeReason: { id: 'full', name: '8 sesiones' }, closedAt: Date.now() }
            })
          // CERRAR INCI
        } else {
          // ACTUALIZAR INC
          newBooking.incident.all.totalSessions = totalSessions
          await firebase.db.collection('incidents').doc(selectedIncident.id).update({ sessions: totalSessions })
        }
      } else {
        newBooking.incident.all.totalSessions = 1
        await firebase.db.collection('incidents').doc(selectedIncident.id).update({ sessions: 1 })

        // ACTUALIZAR INC PONIENDO 1
      }
    }
    if (affectedZone) {
      newBooking.affectedZone = affectedZone
    } else if (selectedIncident.affectedZone) newBooking.affectedZone = selectedIncident.affectedZone
    const updateCalendar = updatedCalendar()
    setStoreCalendar(updateCalendar)

    if (bookingReason.id === labels.es.comunPatology) {
      try {
        if (newBooking.patient.all.medicalHistory) newBooking.patient.all.medicalHistory = medicalHistory
        await firebase.db.collection('users').doc(patient.all.id).update({ medicalHistory: medicalHistory })
        await firebase.db
          .collection('users')
          .doc(patient.all.id)
          .update({ comunPatologyBookings: patient.all.comunPatologyBookings + 1 })
      } catch (error) {
        console.log('fallo', error)
      }
    }

    try {
      if (!dataToEdit) {
        newBooking.createdAt = Date.now()
        newBooking.createdBy = userData.email
        newBooking.deleted = false
        if (userData.companyId && userData.storeId) {
          newBooking.companyId = userData.companyId
          newBooking.storeId = userData.storeId
          newBooking.company = userData.company
          newBooking.store = userData.store
        } else if (userData.companyId) {
          newBooking.companyId = userData.companyId
          newBooking.storeId = store.id
          newBooking.company = userData.company
          newBooking.store = store
        } else {
          newBooking.companyId = company.id
          newBooking.storeId = store.id
          newBooking.company = company
          newBooking.store = store
        }

        await updateStoreCalendar(store?.id || userData.store.id, storeCalendar)
        await createBooking(newBooking)
        await sendNewBookingNotification(newBooking)

        showSnackbar('success', labels.es.bookingCreated)
        await finishSaving()
      } else {
        if (userData.companyId && userData.storeId) {
          newBooking.companyId = userData.companyId
          newBooking.storeId = userData.storeId
        } else if (userData.companyId) {
          newBooking.companyId = userData.companyId
          newBooking.storeId = store.id
          newBooking.company = userData.company
          newBooking.store = store
        } else {
          newBooking.companyId = company.id
          newBooking.storeId = store.id
        }

        if (dataToEdit.date !== newBooking.date || dataToEdit.hourFormatted !== newBooking.hourFormatted) {
          Swal.fire(editBookingsSwalConfig(labels.es.booking, theme.palette.primary.main)).then(async (result) => {
            if (result.isConfirmed) {
              createIssue({
                ...dataToEdit,
                issueType: 'Modificación',
                modifiedAt: Date.now(),
                modifyReason: result.value,
                editedBy: userData?.name || '',
                editedByLastname: userData?.lastname || '',
                editedById: userData.id || 'no tiene',
                newDate: newBooking.date,
                newHour: newBooking.hourFormatted
              }).then(async (docRef) => {
                await firebase.db
                  .collection('issues')
                  .doc(docRef.id)
                  .update({ issueId: docRef.id })
                  .then(async () => {
                    updateStoreCalendar(store.id, storeCalendar)
                  })
                  .then(async () => {
                    await updateBooking(newBooking)
                  })
                  .then(async () => {
                    await finishSaving()
                  })
                  .catch((error: any) => {
                    if (error instanceof Error) showSnackbar('error', error.message)
                  })
              })
            }
          })
        } else {
          await updateStoreCalendar(store.id, storeCalendar)
          await updateBooking(newBooking)
          await sendEditBookingNotification(newBooking)
          await finishSaving()
        }
      }
    } catch (error: any) {
      showSnackbar('error', error.message)
    }
  }

  const handleDelete = () => {
    if (dataToEdit && onDelete) onDelete(dataToEdit.id)
  }

  const loadCompanyStores = async () => {
    if (userData.role.id === 'role3') {
      const companyStores = await getAllStoresByCompany(userData.companyId)

      if (companyStores && companyStores.length) setAllCompanyStores(companyStores.filter((elm: any) => !elm.deleted))
    } else if (userData.role.id !== 'role2') {
      const companyStores = await getAllStoresByCompany(company.id)

      if (companyStores && companyStores.length) setAllCompanyStores(companyStores.filter((elm: any) => !elm.deleted))
    }
  }

  const loadPhysioStores = async () => {
    if (userData.role.id === 'role2') {
      if (userData && userData?.storesWorking?.length > 0) setAllCompanyStores(userData.storesWorking)
    }
  }

  useEffect(() => {
    company && loadCompanyStores()
  }, [company])

  useEffect(() => {
    if (userData.role.id === 'role3') loadCompanyStores()
    if (userData.role.id === 'role2') loadPhysioStores()
    if (!openModal) resetComponent()
  }, [userData, openModal])

  return (
    <Modal
      title={editing ? labels.es.editBooking : labels.es.addBooking}
      open={openModal}
      handleClose={closeModal}
      footer={
        <FooterForm
          text={labels.es.booking}
          closeModal={closeModal}
          onSave={onSave}
          onDelete={onDelete ? handleDelete : undefined}
          editing={editing}
          loading={loading}
          disabled={
            !patient ||
            !selectedDate ||
            !selectedSlot ||
            !bookingReason ||
            (bookingReason?.id === labels.es.campaign && !selectedCampaign) ||
            (bookingReason?.id === labels.es.incident && !selectedIncident) ||
            (bookingReason?.id === labels.es.campaign && !affectedZone)
          }
        />
      }
    >
      <Box id="form-booking">
        {/* {editing && (
          <TextInput disabled value={values.bookingId} name="bookingId" text={labels.es.bookingId} placeholder={labels.es.bookingId} />
        )} */}
        {userData.role.id === 'role1' && (
          <>
            <SelectDialog
              text={labels.es.company}
              setData={setCompany}
              data={company}
              options={allCompanies
                .filter((elm: any) => !elm.deleted)
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((elm: any) => {
                  return { name: elm.name, id: elm.id }
                })}
            />

            {allCompanyStores.length > 0 && (
              <SelectDialog
                text={labels.es.store}
                setData={setStore}
                data={store}
                options={allCompanyStores
                  .filter((elm: any) => !elm.deleted)
                  .sort((a, b) => a.storeName.localeCompare(b.storeName))
                  .map((elm: any) => {
                    return { name: elm.storeName, id: elm.id }
                  })}
              />
            )}
          </>
        )}
        {userData.role.id === 'role2' && (
          <>
            {allCompanyStores.length > 0 && (
              <SelectDialog
                text={labels.es.store}
                setData={setStore}
                data={store}
                options={
                  allCompanyStores
                    // .filter((elm: any) => !elm.deleted)
                    .sort((a, b) => a.name.localeCompare(b.name))
                  // .map((elm: any) => {
                  //   return { name: elm.storeName, id: elm.id }
                  // })}
                }
              />
            )}
          </>
        )}
        {userData.role.id === 'role3' && (
          <>
            {allCompanyStores.length > 0 && (
              <SelectDialog
                text={labels.es.store}
                setData={setStore}
                data={store}
                options={allCompanyStores
                  .filter((elm: any) => !elm.deleted)
                  .sort((a, b) => a.storeName.localeCompare(b.storeName))
                  .map((elm: any) => {
                    return { name: elm.storeName, id: elm.id }
                  })}
              />
            )}
          </>
        )}

        <SelectDialog
          text={labels.es.bookingReason}
          setData={setBookingReason}
          data={bookingReason}
          options={[
            { name: labels.es.campaign, id: labels.es.campaign },
            { name: labels.es.incident, id: labels.es.incident },
            { name: labels.es.comunPatology, id: labels.es.comunPatology }
          ]}
        />

        {bookingReason && companyUsers.length > 0 && (
          <SelectDialog
            disabled={dataToEdit && !(userData.role.id === 'role1')}
            text={labels.es.worker}
            setData={setPatient}
            data={patient}
            options={companyUsers
              .filter((elm: any) => !elm.deleted)
              .filter((elm: any) => (bookingReason?.id === labels.es.comunPatology ? elm.comunPatology.id : elm))
              .sort((a: any, b: any) => a.name.localeCompare(b.name))
              .map((elm: any) => {
                return {
                  name: `${elm.name} ${elm.lastname || ''} ${elm.lastname2 || ''}`,
                  id: elm.id,
                  employeeId: elm.employeeId,
                  all: elm
                }
              })}
          />
        )}

        {bookingReason?.id === labels.es.comunPatology && (
          <TextInput
            value={medicalHistory || ''}
            handleChange={(e) => setMedicalHistory(e.target.value)}
            name="medicalHistory"
            text={labels.es.medicalHistory}
            placeholder={labels.es.medicalHistory}
          />
        )}

        {companyCampaigns.length > 0 && !selectedIncident && bookingReason?.id === labels.es.campaign && (
          <SelectDialog
            text={labels.es.campaign}
            setData={setSelectedCampaign}
            data={selectedCampaign}
            options={companyCampaigns.map((elm: any) => {
              return { name: elm.sector.name + '-' + elm.description + '[' + elm.name + ']', id: elm.id, all: elm }
            })}
          />
        )}
        {companyIncidents.length > 0 && !selectedCampaign && bookingReason?.id === labels.es.incident && (
          <SelectDialog
            text={labels.es.incident}
            setData={setSelectedIncident}
            data={selectedIncident}
            options={companyIncidents
              .filter((elm: any) => !elm.deleted)
              .sort((a: any, b: any) => a.name.localeCompare(b.name))
              .map((elm: any) => {
                return { name: elm.name, id: elm.id, affectedZone: elm.affectedZone, all: elm }
              })}
          />
        )}
        {(selectedCampaign || bookingReason?.id === labels.es.comunPatology) && (
          <SelectDialog
            text={labels.es.affectedZone}
            setData={setAffectedZone}
            data={affectedZone}
            options={pathologies.filter((elm: any) => !elm.deleted).sort((a, b) => a.name.localeCompare(b.name))}
          />
        )}

        {store && storeCalendar && (
          <TextInput
            value={selectedDate}
            handleChange={(e) => handleChangeDate(e.target.value)}
            name="date"
            text={labels.es.date}
            placeholder={labels.es.date}
            type="date"
            shouldDisableDate={checkDate}
          />
        )}

        {['role5', 'role4'].includes(userData.role.id) && storeCalendar && !dataToEdit && (
          <TextInput
            value={selectedDate}
            handleChange={(e) => handleChangeDate(e.target.value)}
            name="date"
            text={labels.es.date}
            placeholder={labels.es.date}
            type="date"
            shouldDisableDate={checkDate}
          />
        )}
        {selectedDate && availableSlots.length > 0 && (
          <MainBox>
            <GridFlexBetween container spacing={2}>
              <Grid item xs={4}>
                <Typography variant="subtitle2" component="h6">
                  {labels.es.timezone}
                </Typography>
              </Grid>
              <Grid item xs={8} sx={{ position: 'relative' }}>
                <SimpleSelect
                  name="selected-slot"
                  handleChange={handleChangeSlot}
                  data={selectedSlot}
                  options={availableSlots}
                  minWidth={100}
                />
              </Grid>
            </GridFlexBetween>
          </MainBox>
        )}

        <TextInput
          value={values.notes}
          handleChange={(e) => handleChange(e)}
          name="notes"
          text={labels.es.notes}
          placeholder={labels.es.notes}
        />
      </Box>
    </Modal>
  )
}
