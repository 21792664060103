import React, { useState, useEffect, useContext } from 'react'
import Box from '@mui/material/Box'
import TextInput from 'src/components/Form/TextInput'
import Modal from 'src/components/Modals/Modal'
import FooterForm from 'src/components/Form/FooterForm'
import { AddEditProps } from 'src/types/pages.types'
import { useForm } from 'src/hooks/useForm'
import { AlertContext } from 'src/context/alert/alertContext'
import { AuthContext } from 'src/context/auth/authContext'
import { InfoContext } from 'src/context/info/infoContext'
import { labels } from 'src/labels/main_labels'
import {
  createBooking,
  getAllCampaignsByCompany,
  getAllIncidentsByCompany,
  sendNewBookingNotification
} from 'src/services/bookings.services'
import { getAllStoresByCompany, updateStoreCalendar } from 'src/services/stores.services'
import { getAllUsersByCompanyAndStore } from 'src/services/users.services'
import SelectDialog from 'src/components/Form/SelectDialog'
import { SelectChangeEvent, Grid, Typography, useTheme, Stack } from '@mui/material'
import SimpleSelect from 'src/components/Form/Select'
import { GridFlexBetween, MainBox } from 'src/components/Form/form.styles'
import { getAllCampaignsByCompanyAndStore } from 'src/services/campaigns.services'
import { isBefore, isSameDay } from 'date-fns'
import { getAllIncidentsByCompanyAndStore } from 'src/services/incidents.services'
import { HeaderContext } from 'src/context/header/headerContext'
import { AddButton } from 'src/components/Buttons/AddButton'

const INITIAL_VALUES = {
  bookingId: '',
  notes: ''
}

export default function BookingsInterStore({ dataToEdit }: AddEditProps): JSX.Element {
  const { showHeader } = useContext(HeaderContext)
  const theme = useTheme()
  const { showSnackbar } = useContext(AlertContext)
  const { userData, firebase } = useContext(AuthContext)
  const { pathologies, allCompanies, fetchPathologies } = useContext(InfoContext)
  const { handleChange, values, resetForm } = useForm(INITIAL_VALUES, dataToEdit)
  const [loading, setLoading] = useState(false)
  const [companyUsers, setCompanyUsers] = useState([])
  const [companyIncidents, setCompanyIncidents] = useState([])
  const [companyCampaigns, setCompanyCampaigns] = useState([])
  const [patient, setPatient] = useState<any | never>(null)
  const [medicalHistory, setMedicalHistory] = useState<any | never>('')
  const [selectedDate, setSelectedDate] = useState<any | never>('')
  const [selectedSlot, setSelectedSlot] = useState<any | never>('')
  const [selectedCampaign, setSelectedCampaign] = useState<any | never>(null)
  const [selectedIncident, setSelectedIncident] = useState<any | never>(null)
  const [bookingReason, setBookingReason] = useState<any | never>(null)
  const [affectedZone, setAffectedZone] = useState<any | never>(null)
  const [availableSlots, setAvailableSlots] = useState([])
  const [allCompanyStores, setAllCompanyStores] = useState<any[]>([])
  const [company, setCompany] = useState<any | never>(null)
  const [store, setStore] = useState<any | never>(null)
  const [patientStore, setPatientStore] = useState<any | never>(null)
  const [storeCalendar, setStoreCalendar] = useState<any | never>(null)
  const [openModal, setOpenModal] = useState(false)

  const resetComponent = () => {
    setLoading(false)
    resetForm()
    setPatient(null)
    setSelectedCampaign(null)
    setSelectedIncident(null)
    setSelectedDate('')
    setSelectedSlot('')
    setMedicalHistory('')
    setAvailableSlots([])
    setCompanyCampaigns([])
    setBookingReason(null)
    setCompanyIncidents([])
    setCompanyUsers([])
    setCompany(null)
    setAllCompanyStores([])
    setStore(null)
    setPatientStore(null)
    setAffectedZone(null)
  }

  const Header = () => {
    return (
      <Stack direction="row" justifyContent="flex-end">
        {/* <SearchInput placeholder={labels.es.searchBookings} name="search" handleSubmit={setSearchString} clean fullwidth /> */}
        {userData.role.id !== 'role6' && <AddButton onClick={() => setOpenModal(true)} text={'Añadir cita entre almacenes'} />}
      </Stack>
    )
  }

  useEffect(() => {
    // if (!initHeader) return
    showHeader(Header)
  }, [])

  const checkDate = (date: Date) => {
    const day = storeCalendar.filter(
      (book: any) => date.getDate() === book.day && date.getMonth() === book.month && date.getFullYear() === book.year
    )
    if (isBefore(new Date(), date) || isSameDay(new Date(), date)) {
      return Boolean(day ? !day.some((elm: any) => elm.reserved === false) : !day)
    } else return true
  }

  const handleSlotsAvailables = () => {
    const date = new Date(selectedDate)
    const sameDayBookings = storeCalendar?.filter((book: any) => {
      return date.getDate() === book.day && date.getMonth() === book.month && date.getFullYear() === book.year && !book.reserved
    })
    const slotsAvailables = sameDayBookings.map(
      (book: any) => String(book.hour).padStart(2, '0') + ':' + String(book.minutes).padStart(2, '0')
    )

    setAvailableSlots(
      slotsAvailables.map((elm: string) => {
        return { value: elm, label: elm }
      })
    )
  }

  const getStoreCalendar = async (storeName: string) => {
    await firebase.db
      .collection('stores')
      .where('storeName', '==', storeName)
      .onSnapshot((querySnapshot: any) => {
        const store: any[] = []
        querySnapshot.forEach((doc: any) => {
          setStoreCalendar(doc.data().calendar)
        })
      })
  }

  useEffect(() => {
    if (selectedDate && storeCalendar) {
      handleSlotsAvailables()
    }
  }, [selectedDate, storeCalendar])

  const handleChangeDate = (newValue: Date | null) => {
    setSelectedDate(newValue)

    if (availableSlots.length > 0) setSelectedSlot('')
  }

  const handleChangeSlot = (e: SelectChangeEvent<string>) => {
    setSelectedSlot(e.target.value)
  }

  const loadUsers = async () => {
    if (userData && userData.companyId && patientStore) {
      const users = await getAllUsersByCompanyAndStore(userData.companyId, patientStore.id)

      if (users && users.length > 0) setCompanyUsers(users)
    }
  }

  const loadAdminUsers = async () => {
    if (userData && userData.role.id === 'role1' && patientStore && company) {
      const users = await getAllUsersByCompanyAndStore(company.id, patientStore.id)
      if (users && users.length) {
        setCompanyUsers(users)
      } else {
        setCompanyUsers([])
      }
    }
  }

  const loadCampaigns = async () => {
    if (userData && userData.companyId && patientStore) {
      const campaigns = await getAllCampaignsByCompanyAndStore(userData.companyId, patientStore.id)
      if (campaigns && campaigns.length) {
        setCompanyCampaigns(campaigns)
      }
    }
  }

  const loadIncidents = async () => {
    if (userData && userData.companyId && patientStore) {
      const incidents = await getAllIncidentsByCompanyAndStore(userData.companyId, patientStore)
      if (incidents && incidents.length) {
        const filterByUser = incidents.filter((incident: any) => {
          return incident.injuredWorker.id === patient.id && !incident.closed
        })
        setCompanyIncidents(filterByUser)
      }
    }
  }

  const loadAdminCampaingns = async () => {
    if (userData && userData.role.id === 'role1') {
      const campaigns = await getAllCampaignsByCompany(company.id)
      if (campaigns && campaigns.length) {
        setCompanyCampaigns(campaigns.filter((elm: any) => !elm.deleted && elm.storeId === patientStore.id))
      }
    }
  }

  const loadAdminIncidents = async () => {
    if (!company && userData.role.id === 'role1') return
    if (userData && userData.role.id === 'role1') {
      const incidents = await getAllIncidentsByCompany(company.id)
      if (incidents && incidents.length) {
        const filterByUser = incidents.filter((incident: any) => incident.injuredWorker?.id === patient.id && !incident.closed)
        setCompanyIncidents(filterByUser)
      }
    }
  }

  useEffect(() => {
    if (userData) {
      loadUsers()
      loadCampaigns()
    }
  }, [patientStore])

  useEffect(() => {
    if (patient) loadIncidents()
    if (patient) loadAdminIncidents()
  }, [patient])

  useEffect(() => {
    if (store && userData.role.id === 'role1') {
      loadAdminUsers()
      loadAdminCampaingns()
      fetchPathologies(company.id)
    }
  }, [patientStore, userData])

  useEffect(() => {
    if (store) getStoreCalendar(store?.name)
    if (userData?.store?.id) getStoreCalendar(userData?.store?.name)
  }, [store, userData])

  const updatedCalendar = () => {
    const updateCalendar = storeCalendar.map((book: any) => {
      const dateFromDataBase = new Date(book.year, book.month, book.day).toLocaleDateString('fr-CA')
      const hourFromDataBase = `${String(book.hour).padStart(2, '0')}:${String(book.minutes).padStart(2, '0')}`
      if (selectedDate === dateFromDataBase && selectedSlot === hourFromDataBase) {
        book.reserved = true
      }

      return book
    })
    return updateCalendar
  }

  const finishSaving = async () => {
    resetComponent()
    setLoading(false)
    showSnackbar('success', labels.es.bookingUpdated)
    setOpenModal(false)
  }

  const onSave = async () => {
    setLoading(true)
    const newBooking = JSON.parse(JSON.stringify(values))
    newBooking.updatedAt = Date.now()
    newBooking.patient = patient
    newBooking.completed = false
    newBooking.date = selectedDate
    newBooking.year = new Date(selectedDate).getFullYear()
    newBooking.month = new Date(selectedDate).getMonth()
    newBooking.day = new Date(selectedDate).getDate()
    newBooking.hourFormatted = selectedSlot
    newBooking.hour = selectedSlot.substring(0, 2)
    newBooking.minutes = selectedSlot.substring(3, 5)
    newBooking.bookingReason = bookingReason || null
    newBooking.campaign = selectedCampaign || null
    newBooking.incident = selectedIncident || null
    if (selectedIncident) {
      if (selectedIncident.all.sessions) {
        const totalSessions = selectedIncident.all.sessions + 1

        if (totalSessions === 8) {
          newBooking.incident.all.totalSessions = totalSessions
          await firebase.db
            .collection('incidents')
            .doc(selectedIncident.id)
            .update({
              sessions: totalSessions,
              closed: { closedBy: 'system', closeReason: { id: 'full', name: '8 sesiones' }, closedAt: Date.now() }
            })
          // CERRAR INCI
        } else {
          // ACTUALIZAR INC
          newBooking.incident.all.totalSessions = totalSessions
          await firebase.db.collection('incidents').doc(selectedIncident.id).update({ sessions: totalSessions })
        }
      } else {
        newBooking.incident.all.totalSessions = 1
        await firebase.db.collection('incidents').doc(selectedIncident.id).update({ sessions: 1 })

        // ACTUALIZAR INC PONIENDO 1
      }
    }
    if (affectedZone) {
      newBooking.affectedZone = affectedZone
    } else if (selectedIncident.affectedZone) newBooking.affectedZone = selectedIncident.affectedZone
    const updateCalendar = updatedCalendar()
    setStoreCalendar(updateCalendar)

    if (bookingReason.id === labels.es.comunPatology) {
      try {
        if (newBooking.patient.all.medicalHistory) newBooking.patient.all.medicalHistory = medicalHistory
        await firebase.db.collection('users').doc(patient.all.id).update({ medicalHistory: medicalHistory })
        await firebase.db
          .collection('users')
          .doc(patient.all.id)
          .update({ comunPatologyBookings: patient.all.comunPatologyBookings + 1 })
      } catch (error) {
        console.log('fallo', error)
      }
    }

    try {
      newBooking.createdAt = Date.now()
      newBooking.createdBy = userData.email
      newBooking.deleted = false
      newBooking.interStore = true
      if (userData.companyId && userData.storeId) {
        newBooking.companyId = userData.companyId
        newBooking.storeId = userData.storeId
        newBooking.company = userData.company
        newBooking.store = userData.store
      } else if (userData.companyId) {
        newBooking.companyId = userData.companyId
        newBooking.storeId = store.id
        newBooking.company = userData.company
        newBooking.store = store
      } else {
        newBooking.companyId = company.id
        newBooking.storeId = store.id
        newBooking.company = company
        newBooking.store = store
      }

      await updateStoreCalendar(store?.id || userData.store.id, storeCalendar)
      await createBooking(newBooking)
      await sendNewBookingNotification(newBooking)

      showSnackbar('success', labels.es.bookingCreated)
      await finishSaving()
    } catch (error: any) {
      showSnackbar('error', error.message)
    }
  }

  const loadCompanyStores = async () => {
    if (userData.role.id === 'role5') {
      const companyStores = await getAllStoresByCompany(userData.companyId)

      if (companyStores && companyStores.length) setAllCompanyStores(companyStores.filter((elm: any) => !elm.deleted))
    } else if (userData.role.id === 'role1') {
      const companyStores = await getAllStoresByCompany(company.id)

      if (companyStores && companyStores.length) setAllCompanyStores(companyStores.filter((elm: any) => !elm.deleted))
    }
  }

  useEffect(() => {
    company && loadCompanyStores()
  }, [company])

  useEffect(() => {
    if (bookingReason) setPatient(null)
  }, [bookingReason])

  useEffect(() => {
    if (userData.role.id === 'role5') loadCompanyStores()
    if (!openModal) resetComponent()
  }, [userData, openModal])

  return (
    <Modal
      title={labels.es.addBookingInterStores}
      open={openModal}
      handleClose={() => setOpenModal(false)}
      footer={
        <FooterForm
          text={labels.es.booking}
          closeModal={() => setOpenModal(false)}
          onSave={onSave}
          editing={false}
          loading={loading}
          disabled={
            !patient ||
            !selectedDate ||
            !selectedSlot ||
            !bookingReason ||
            (bookingReason?.id === labels.es.campaign && !selectedCampaign) ||
            (bookingReason?.id === labels.es.incident && !selectedIncident) ||
            (bookingReason?.id === labels.es.campaign && !affectedZone)
          }
        />
      }
    >
      <Box id="form-booking">
        {/* {editing && (
          <TextInput disabled value={values.bookingId} name="bookingId" text={labels.es.bookingId} placeholder={labels.es.bookingId} />
        )} */}
        {userData.role.id === 'role1' && (
          <>
            <SelectDialog
              text={labels.es.company}
              setData={setCompany}
              data={company}
              options={allCompanies
                .filter((elm: any) => !elm.deleted)
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((elm: any) => {
                  return { name: elm.name, id: elm.id }
                })}
            />

            {allCompanyStores.length > 0 && (
              <SelectDialog
                text={'Tienda'}
                setData={setStore}
                data={store}
                options={allCompanyStores
                  .filter((elm: any) => !elm.deleted)
                  .sort((a, b) => a.storeName.localeCompare(b.storeName))
                  .map((elm: any) => {
                    return { name: elm.storeName, id: elm.id }
                  })}
              />
            )}
          </>
        )}
        {allCompanyStores.length > 0 && (
          <SelectDialog
            text={'Tienda Colaborador'}
            setData={setPatientStore}
            data={patientStore}
            options={allCompanyStores
              .filter((elm: any) => !elm.deleted)
              .sort((a, b) => a.storeName.localeCompare(b.storeName))
              .map((elm: any) => {
                return { name: elm.storeName, id: elm.id }
              })}
          />
        )}

        <SelectDialog
          text={labels.es.bookingReason}
          setData={setBookingReason}
          data={bookingReason}
          options={[
            { name: labels.es.campaign, id: labels.es.campaign },
            { name: labels.es.incident, id: labels.es.incident },
            { name: labels.es.comunPatology, id: labels.es.comunPatology }
          ]}
        />

        {bookingReason && companyUsers.length > 0 && (
          <SelectDialog
            // disabled={!(userData.role.id === 'role1')}
            text={labels.es.worker}
            setData={setPatient}
            data={patient}
            options={companyUsers
              .filter((elm: any) => !elm.deleted)
              .filter((elm: any) => (bookingReason?.id === labels.es.comunPatology ? elm.comunPatology.id : elm))
              .sort((a: any, b: any) => a.name.localeCompare(b.name))
              .map((elm: any) => {
                return {
                  name: `${elm.name} ${elm.lastname || ''} ${elm.lastname2 || ''}`,
                  id: elm.id,
                  employeeId: elm.employeeId,
                  all: elm
                }
              })}
          />
        )}

        {bookingReason?.id === labels.es.comunPatology && (
          <TextInput
            value={medicalHistory || ''}
            handleChange={(e) => setMedicalHistory(e.target.value)}
            name="medicalHistory"
            text={labels.es.medicalHistory}
            placeholder={labels.es.medicalHistory}
          />
        )}

        {companyCampaigns.length > 0 && !selectedIncident && bookingReason?.id === labels.es.campaign && (
          <SelectDialog
            text={labels.es.campaign}
            setData={setSelectedCampaign}
            data={selectedCampaign}
            options={companyCampaigns.map((elm: any) => {
              return { name: elm.sector.name + '-' + elm.description + '[' + elm.name + ']', id: elm.id, all: elm }
            })}
          />
        )}
        {companyIncidents.length > 0 && !selectedCampaign && bookingReason?.id === labels.es.incident && (
          <SelectDialog
            text={labels.es.incident}
            setData={setSelectedIncident}
            data={selectedIncident}
            options={companyIncidents
              .filter((elm: any) => !elm.deleted)
              .sort((a: any, b: any) => a.name.localeCompare(b.name))
              .map((elm: any) => {
                return { name: elm.name, id: elm.id, affectedZone: elm.affectedZone, all: elm }
              })}
          />
        )}
        {(selectedCampaign || bookingReason?.id === labels.es.comunPatology) && (
          <SelectDialog
            text={labels.es.affectedZone}
            setData={setAffectedZone}
            data={affectedZone}
            options={pathologies.filter((elm: any) => !elm.deleted).sort((a, b) => a.name.localeCompare(b.name))}
          />
        )}

        {['role5', 'role1'].includes(userData.role.id) && storeCalendar && (
          <TextInput
            value={selectedDate}
            handleChange={(e) => handleChangeDate(e.target.value)}
            name="date"
            text={labels.es.date}
            placeholder={labels.es.date}
            type="date"
            shouldDisableDate={checkDate}
          />
        )}

        {selectedDate && availableSlots.length > 0 && (
          <MainBox>
            <GridFlexBetween container spacing={2}>
              <Grid item xs={4}>
                <Typography variant="subtitle2" component="h6">
                  {labels.es.timezone}
                </Typography>
              </Grid>
              <Grid item xs={8} sx={{ position: 'relative' }}>
                <SimpleSelect
                  name="selected-slot"
                  handleChange={handleChangeSlot}
                  data={selectedSlot}
                  options={availableSlots}
                  minWidth={100}
                />
              </Grid>
            </GridFlexBetween>
          </MainBox>
        )}

        <TextInput
          value={values.notes}
          handleChange={(e) => handleChange(e)}
          name="notes"
          text={labels.es.notes}
          placeholder={labels.es.notes}
        />
      </Box>
    </Modal>
  )
}
