import IRoute from '../types/routes.types'
import UsuariosTiendaPage from '../pages/AdminPlatform/Users/UsersAlgolia'
import EmpresasPage from '../pages/AdminPlatform/Companies/Companies'
import TiendasPage from '../pages/AdminPlatform/Stores/Stores'
import Calendars from '../pages/AdminPlatform/Calendars'
import Pathologies from 'src/pages/AdminPlatform/Pathologies'
import Processes from 'src/pages/AdminPlatform/Processes'
import Sectors from 'src/pages/AdminPlatform/Sectors'
import Areas from 'src/pages/AdminPlatform/Areas'
import Machines from 'src/pages/AdminPlatform/Machines'
import Statitics from 'src/pages/AdminPlatform/Statitics'
import Incidents from 'src/pages/AdminPlatform/Incidents'
import Campaigns from 'src/pages/AdminPlatform/Campaigns'
import Ayuda from 'src/pages/Ayuda/Ayuda'
import Utilidades from 'src/pages/Utilidades/Utilidades'
import Horarios from 'src/pages/Ayuda/Horarios'
import Bookings from 'src/pages/AdminPlatform/Bookings'
import BookingsDetail from 'src/pages/AdminPlatform/Bookings/BookingsDetail'
import Issues from 'src/pages/AdminPlatform/Issues'
import IssuesDetail from 'src/pages/AdminPlatform/Issues/IssuesDetail'
import UsersDetail from 'src/pages/AdminPlatform/Users/UsersDetails'
import CampaignsDetail from 'src/pages/AdminPlatform/Campaigns/CampaignsDetail'
import IncidentsDetail from 'src/pages/AdminPlatform/Incidents/IncidentsDetail'
import Roles from 'src/pages/AdminPlatform/Roles'
import Chat from 'src/pages/Utilidades/Chat'
import Encuesta from 'src/pages/Utilidades/Encuesta'
import ExportarQuerys from 'src/pages/Utilidades/ExportarQuerys'
import PoliticaPrivacidad from 'src/pages/Ayuda/PoliticaPrivacidad'
import AvisoLegal from 'src/pages/Ayuda/AvisoLegal'
import BookingsInterStore from 'src/pages/AdminPlatform/Bookings/BookingsInterStores'

const adminRoutes: IRoute[] = [
  {
    path: '/companias',
    name: 'Compañias',
    component: EmpresasPage,
    exact: true,
    roles: ['role1']
  },
  {
    path: '/tiendas',
    name: 'Tiendas',
    component: TiendasPage,
    exact: true,
    roles: ['role1']
  },
  {
    path: '/usuarios',
    name: 'Usuarios',
    component: UsuariosTiendaPage,
    exact: true,
    roles: ['role1', 'role3', 'role4', 'role5']
  },
  {
    path: '/usuarios/:idUser',
    name: 'Users-detail',
    component: UsersDetail,
    exact: true,
    roles: ['role1', 'role3', 'role4', 'role5', 'role2']
  },
  {
    path: '/calendarios',
    name: 'Calendars',
    component: Calendars,
    exact: true,
    roles: ['role1', 'role2']
  },
  {
    path: '/roles',
    name: 'Roles',
    component: Roles,
    exact: true,
    roles: ['role1', 'role3']
  },
  {
    path: '/estadisticas',
    name: 'Statitics',
    component: Statitics,
    exact: true,
    roles: ['role1', 'role3', 'role4']
  },
  {
    path: '/citas',
    name: 'Bookings',
    component: Bookings,
    exact: true,
    roles: ['role1', 'role3', 'role4', 'role2', 'role5', 'role6']
  },
  {
    path: '/citas/:idCita',
    name: 'Bookings-detail',
    component: BookingsDetail,
    exact: true,
    roles: ['role1', 'role3', 'role4', 'role5', 'role2']
  },
  {
    path: '/incidentes',
    name: 'Incidents',
    component: Incidents,
    exact: true,
    roles: ['role1', 'role3', 'role4', 'role5', 'role6']
  },
  {
    path: '/incidentes/:idIncident',
    name: 'Incidents-detail',
    component: IncidentsDetail,
    exact: true,
    roles: ['role1', 'role3', 'role4', 'role5', 'role6']
  },
  {
    path: '/campanas',
    name: 'Campaigns',
    component: Campaigns,
    exact: true,
    roles: ['role1', 'role3', 'role4', 'role5']
  },
  {
    path: '/campanas/:idCampaign',
    name: 'Campaigns-detail',
    component: CampaignsDetail,
    exact: true,
    roles: ['role1', 'role3', 'role4', 'role5']
  },
  {
    path: '/patologias',
    name: 'Pathologies',
    component: Pathologies,
    exact: true,
    roles: ['role1']
  },
  {
    path: '/areas',
    name: 'Areas',
    component: Areas,
    exact: true,
    roles: ['role1', 'role3']
  },
  {
    path: '/procesos',
    name: 'Procesos',
    component: Processes,
    exact: true,
    roles: ['role1', 'role3']
  },
  {
    path: '/sectores',
    name: 'Sectores',
    component: Sectors,
    exact: true,
    roles: ['role1', 'role3']
  },
  {
    path: '/maquinaria',
    name: 'Maquinaria',
    component: Machines,
    exact: true,
    roles: ['role1', 'role3']
  },
  {
    path: '/incidencias',
    name: 'Issues',
    component: Issues,
    exact: true,
    roles: ['role1', 'role3', 'role4', 'role2', 'role5']
  },
  {
    path: '/interalmacenes',
    name: 'Inter-Almacenes',
    component: BookingsInterStore,
    exact: true,
    roles: ['role1', 'role2', 'role3', 'role4', 'role5']
  },
  {
    path: '/incidencias/:idIncidencia',
    name: 'issues-detail',
    component: IssuesDetail,
    exact: true,
    roles: ['role1', 'role3', 'role4', 'role5', 'role2']
  },
  {
    path: '/chat',
    name: 'chat',
    component: Chat,
    exact: true,
    roles: ['role1', 'role2', 'role5']
  },
  {
    path: '/encuesta',
    name: 'encuesta',
    component: Encuesta,
    exact: true,
    roles: ['role1', 'role3', 'role4', 'role5', 'role6']
  },
  {
    path: '/exportar-datos',
    name: 'exportar-datos',
    component: ExportarQuerys,
    exact: true,
    roles: ['role1', 'role3', 'role4', 'role5']
  },
  {
    path: '/ayuda',
    name: 'Ayuda',
    component: Ayuda,
    exact: true,
    roles: ['role1', 'role3', 'role2', 'role4', 'role5', 'role6']
  },
  {
    path: '/utilidades',
    name: 'Utilidades',
    component: Utilidades,
    exact: true,
    roles: ['role1', 'role3', 'role2', 'role4', 'role5', 'role6']
  },
  {
    path: '/politica-de-privacidad',
    name: 'Política de Privacidad',
    component: PoliticaPrivacidad,
    exact: true,
    roles: ['role1', 'role3', 'role2', 'role4', 'role5', 'role6']
  },
  {
    path: '/aviso-legal',
    name: 'Aviso Legal',
    component: AvisoLegal,
    exact: true,
    roles: ['role1', 'role3', 'role2', 'role4', 'role5', 'role6']
  },
  {
    path: '/dias',
    name: 'Dias de Servicio',
    component: Horarios,
    exact: true,
    roles: ['role1', 'role3', 'role2', 'role4', 'role5', 'role6']
  }
]

export default adminRoutes
