import React, { useState, useEffect, useContext } from 'react'
import Box from '@mui/material/Box'
import TextInput from 'src/components/Form/TextInput'
import Modal from 'src/components/Modals/Modal'
import FooterForm from 'src/components/Form/FooterForm'
import { AddEditProps } from '../../../types/pages.types'
import { useForm } from 'src/hooks/useForm'
import { AlertContext } from 'src/context/alert/alertContext'
import { InfoContext } from 'src/context/info/infoContext'
import { AuthContext } from 'src/context/auth/authContext'
import { labels } from 'src/labels/main_labels'
import { createUser, updateUser } from 'src/services/users.services'
import { getAllCompanies, getAllCompanyConfig, checkEmployeesIds } from 'src/services/companies.services'
import { getAllStoresByCompany } from 'src/services/stores.services'
import { validateEmail } from 'src/utils/validateForm'
import SelectDialog from 'src/components/Form/SelectDialog'
import ChipSelector from 'src/components/Form/ChipSelector'

const INITIAL_VALUES = {
  name: '',
  lastname: '',
  lastname2: '',
  role: '',
  email: '',
  workingSince: '',
  birthday: '',
  employeeId: ''
}

export default function UsersAddEdit({ dataToEdit, closeModal, openModal, onDelete, fetchData }: AddEditProps): JSX.Element {
  const editing = !!dataToEdit
  const { showSnackbar } = useContext(AlertContext)
  const { sectors, roles, fetchCompanyConfigData, allStores } = useContext(InfoContext)
  const { handleChange, values, resetForm } = useForm(INITIAL_VALUES, dataToEdit)
  const [loading, setLoading] = useState(false)
  const [errorEmail, setErrorEmail] = useState<string | undefined>(undefined)
  const [errorEmployeeId, setErrorEmployeeId] = useState<string | undefined>(undefined)
  const [company, setCompany] = useState<any | never>(null)
  const [role, setRole] = useState<any | never>('')
  const [allCompanies, setAllCompanies] = useState([])
  const [allCompanyStores, setAllCompanyStores] = useState<any[]>([])
  const [store, setStore] = useState<any | never>(null)
  const [gender, setGender] = useState<any | never>(null)
  const [comunPatology, setComunPatology] = useState<any | never>(null)
  const [sectorSelected, setSectorSelected] = useState<any[] | never>([])
  const [storesWorking, setStoresWorking] = useState<any[] | never>([])
  const [availableRoles, setAvailableRoles] = useState<any[] | never>([])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [configCompanyAdmin, setConfigCompanyAdmin] = useState([])
  // const [allCompanyEmployeesIds, setAllCompanyEmployeesIds] = useState<any[]>([])
  const { userData } = useContext(AuthContext)

  const resetComponent = () => {
    setLoading(false)
    resetForm()
    setErrorEmail(undefined)
    setErrorEmployeeId(undefined)
    setStoresWorking([])
    setCompany('')
    setStore('')
    setSectorSelected([])
    setRole('')
    setGender(null)
    setComunPatology(null)
    setConfigCompanyAdmin([])
    // setAllCompanyEmployeesIds([])
  }

  const loadCompanies = async () => {
    const companies = await getAllCompanies()
    if (companies && companies.length) setAllCompanies(companies.filter((elm: any) => !elm.deleted))
  }

  useEffect(() => {
    loadCompanies()
  }, [])

  const loadCompanyStores = async (hrStoreId?: string) => {
    const companyPre = company?.id || userData.company?.id

    const companyStores = await getAllStoresByCompany(companyPre || hrStoreId)

    if (companyStores && companyStores.length) setAllCompanyStores(companyStores)
  }

  const loadCompanyConfig = async () => {
    const companyConfig = await getAllCompanyConfig(company.id)

    if (companyConfig && companyConfig.length)
      setConfigCompanyAdmin(
        companyConfig.sort((a: any, b: any) => a.name.localeCompare(b.name)).filter((elm: any) => elm.type === 'sectors')
      )
  }

  // const loadEmployeesID = async (idcompany?: string) => {
  //   const companyIds = await getAllEmployeesIds(company?.id || idcompany)

  //   if (companyIds) setAllCompanyEmployeesIds(companyIds)
  // }

  useEffect(() => {
    if (['role3', 'role5'].includes(userData.role.id)) loadCompanyStores(userData.companyId)
    // if (['role3', 'role4', 'role5', 'role6'].includes(userData.role.id)) loadEmployeesID(userData.companyId)
  }, [userData])

  useEffect(() => {
    company && loadCompanyStores()
    company && loadCompanyConfig()
    company && fetchCompanyConfigData(company.id)
    // company && loadEmployeesID()
  }, [company])

  useEffect(() => {
    if (dataToEdit) {
      if (dataToEdit.company) setCompany(dataToEdit.company)
      if (dataToEdit.store) setStore(dataToEdit.store)
      if (dataToEdit.role) setRole(dataToEdit.role)
      if (dataToEdit.storesWorking) setStoresWorking(dataToEdit.storesWorking)
      if (dataToEdit.gender) setGender(dataToEdit.gender)
      if (dataToEdit.comunPatology) setComunPatology(dataToEdit.comunPatology)
      if (dataToEdit.sector) setSectorSelected(dataToEdit.sector)
    }
    return () => resetComponent()
  }, [dataToEdit])

  const validateEmployeeId = async (e: any) => {
    if (!dataToEdit) {
      if (e) {
        const existId = await checkEmployeesIds(e)

        if (existId > 0) {
          setErrorEmployeeId(labels.es.errorEmployeeIdValidation)
          return true
        } else {
          setErrorEmployeeId(undefined)
          return false
        }
      }
    } else {
      if (e && dataToEdit.employeeId !== values.employeeId) {
        const existId = await checkEmployeesIds(e)
        if (existId.length) {
          setErrorEmployeeId(labels.es.errorEmployeeIdValidation)
          return true
        } else {
          setErrorEmployeeId(undefined)
          return false
        }
      }
    }
  }

  const onSave = async () => {
    if (values.employeeId && (await validateEmployeeId(values.employeeId))) return

    setErrorEmail(undefined)

    if (!validateEmail(values.email)) {
      setErrorEmail(labels.es.errorEmailValidation)
    }

    setLoading(true)
    const newUser = JSON.parse(JSON.stringify(values))

    try {
      if (!dataToEdit) {
        if (userData && !['role3', 'role1', 'role5'].includes(userData.role.id) && role.id !== 'role1') {
          newUser.company = { id: userData.company.id, name: userData.company.name }
          newUser.companyId = userData.company.id
          newUser.store = { id: userData.store.id, name: userData.store.name }
          newUser.storeId = userData.store.id
        } else if (userData && userData.role.id === 'role1' && role.id === 'role3') {
          newUser.company = company
          newUser.companyId = company.id
        } else if (userData && ['role3', 'role5'].includes(userData.role.id)) {
          newUser.company = { id: userData.company.id, name: userData.company.name }
          newUser.companyId = userData.company.id
          newUser.store = store
          newUser.storeId = store.id
        } else if (role && role.id !== 'role2' && role.id !== 'role1') {
          newUser.company = company
          newUser.companyId = company.id
          newUser.store = store
          newUser.storeId = store.id
        }

        if (role && role.id === 'role2') newUser.storesWorking = storesWorking

        newUser.createdAt = Date.now()
        newUser.updatedAt = Date.now()
        newUser.comunPatology = comunPatology
        newUser.fullName = newUser?.name + ' ' + newUser?.lastname + ' ' + newUser?.lastname2
        newUser.gender = gender
        if (!(role.id === 'role1' || role.id === 'role3')) newUser.sector = sectorSelected
        newUser.role = role
        newUser.deleted = false

        const resp = await createUser(newUser)
        if (resp && resp.error) showSnackbar('error', resp.error)
        else showSnackbar('success', labels.es.userCreated)
      } else {
        if (role && role.id === 'role2') {
          newUser.updatedAt = Date.now()
          newUser.gender = gender
          newUser.role = role
          newUser.comunPatology = false
          newUser.storesWorking = storesWorking
          await updateUser(newUser, dataToEdit.id)
          showSnackbar('success', labels.es.userUpdated)
        } else {
          newUser.updatedAt = Date.now()
          newUser.gender = gender
          newUser.sector = sectorSelected
          newUser.role = role
          newUser.company = company
          newUser.companyId = company.id
          newUser.store = store
          newUser.comunPatology = comunPatology
          newUser.storeId = store.id
          await updateUser(newUser, dataToEdit.id)
          showSnackbar('success', labels.es.userUpdated)
        }

        await updateUser(newUser, dataToEdit.id)
        showSnackbar('success', labels.es.userUpdated)
      }
      resetComponent()
      if (fetchData) fetchData()
      closeModal()
    } catch (error: any) {
      showSnackbar('error', error.message)
    } finally {
      setLoading(false)
    }
  }

  const handleDelete = () => {
    if (dataToEdit && onDelete) onDelete(dataToEdit.id)
  }

  const isAllFilled = () => {
    if (['role2', 'role1'].includes(role?.id)) {
      if (!values.name || !values.lastname || !values.lastname2 || !values.birthday || !values.email || !gender || !role) return true
      else return false
    } else if (['role6', 'role5'].includes(role?.id)) {
      if (
        !values.name ||
        !values.lastname ||
        !values.lastname2 ||
        !values.birthday ||
        !values.workingSince ||
        !values.employeeId ||
        !values.email ||
        !sectorSelected.length ||
        !comunPatology ||
        !gender ||
        !role
      )
        return true
      else return false
    } else if (
      !values.name ||
      !values.lastname ||
      !values.lastname2 ||
      !values.birthday ||
      !values.workingSince ||
      !values.employeeId ||
      !values.email ||
      !comunPatology ||
      !gender ||
      !role
    )
      return true
    else return false
  }

  const positionJobOptions = (): any => {
    // if (!company) return [{ id: 'role1', name: 'admin' }]
    // else {
    if (userData.role.id === 'role1') {
      const resp = roles.map((elm) => ({ id: elm.role, name: elm.name })).sort((a, b) => a.name.localeCompare(b.name))

      if (!resp.length && company) setAvailableRoles([])
      else if (resp.length && company) setAvailableRoles([...resp, { id: 'role2', name: labels.es.physio }])
      else if (!resp.length)
        setAvailableRoles([
          { id: 'role1', name: labels.es.admin },
          { id: 'role2', name: labels.es.physio }
        ])
    } else if (userData.role.id === 'role3') {
      const availables = roles
        .filter((el) => el.role !== 'role1' && el.role !== 'role2')
        .map((elm) => ({
          id: elm.role,
          name: elm.name
        }))
        .sort((a, b) => a.name.localeCompare(b.name))
      setAvailableRoles(availables)
    } else {
      const availables = roles
        .filter((el) => el.role !== 'role1' && el.role !== 'role3' && el.role !== 'role2')
        .map((elm) => ({
          id: elm.role,
          name: elm.name
        }))
        .sort((a, b) => a.name.localeCompare(b.name))
      setAvailableRoles(availables)
    }
  }

  useEffect(() => {
    positionJobOptions()
  }, [roles, company])

  return (
    <Modal
      title={editing ? dataToEdit.name + ' ' + dataToEdit.lastname + ' ' + dataToEdit?.lastname2 : labels.es.addUser}
      open={openModal}
      handleClose={closeModal}
      footer={
        <FooterForm
          text={labels.es.user}
          closeModal={closeModal}
          onSave={onSave}
          onDelete={onDelete ? handleDelete : undefined}
          editing={editing}
          loading={loading}
          disabled={isAllFilled()}
        />
      }
    >
      <Box id="form-users">
        <TextInput
          required
          value={values.name}
          handleChange={(e) => handleChange(e)}
          name="name"
          text={labels.es.name}
          placeholder={labels.es.name}
        />
        <TextInput
          required
          value={values.lastname}
          handleChange={(e) => handleChange(e)}
          name="lastname"
          text={labels.es.lastname}
          placeholder={labels.es.lastname}
        />
        <TextInput
          required
          value={values.lastname2}
          handleChange={(e) => handleChange(e)}
          name="lastname2"
          text={labels.es.lastname2}
          placeholder={labels.es.lastname2}
        />
        <TextInput
          required
          disabled={editing}
          value={values.email}
          handleChange={(e) => handleChange(e)}
          name="email"
          text={labels.es.email}
          placeholder={labels.es.email}
          errorBadge={errorEmail}
          type="email"
        />
        <TextInput
          required
          value={values.birthday}
          handleChange={(e) => handleChange(e)}
          name="birthday"
          id="birthday"
          text={labels.es.birthday}
          placeholder={labels.es.birthday}
          type="date"
        />
        <SelectDialog
          required
          text={labels.es.gender}
          setData={setGender}
          data={gender}
          options={[
            { name: 'Hombre', id: 'Hombre' },
            { name: 'Mujer', id: 'Mujer' },
            { name: '-', id: '-' }
          ]}
        />
        <SelectDialog
          required
          text={labels.es.comunPatology}
          setData={setComunPatology}
          data={comunPatology}
          options={[
            { name: 'Si', id: true },
            { name: 'No', id: false }
          ]}
        />
        {userData.role.id === 'role1' && !['role1', 'role2'].includes(role?.id) && (
          <SelectDialog
            required
            text={labels.es.company}
            setData={setCompany}
            data={company}
            options={allCompanies.map((elm: any) => {
              return { name: elm.name, id: elm.id }
            })}
          />
        )}
        {availableRoles && (
          <SelectDialog roles required text={labels.es.positionJob} setData={setRole} data={role} options={availableRoles} />
        )}
        {userData.role.id === 'role1' && role.id === 'role2' && allStores && (
          <ChipSelector
            // fieldToSave="id"
            data={storesWorking}
            fieldToSave="labels"
            setData={setStoresWorking}
            options={allStores
              .filter((elm: any) => !elm.deleted)

              .sort((a: any, b: any) => a.storeName.localeCompare(b.storeName))
              .map((elm: any) => {
                return { name: `${elm.storeName || ''}`, id: elm.id }
              })}
            text={labels.es.stores}
          />
        )}
        {sectors.length > 0 &&
          role &&
          !(['role1', 'role3', 'role2', 'role4'].includes(role) || ['role1', 'role3', 'role2', 'role4'].includes(role?.id)) && (
            <ChipSelector
              // fieldToSave="id"
              data={sectorSelected}
              fieldToSave="labels"
              setData={setSectorSelected}
              options={sectors
                .filter((elm: any) => !elm.deleted)
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((elm: any) => {
                  return { name: elm.name, id: elm.id }
                })}
              text={labels.es.sector}
            />
            // <SelectDialog
            //   required
            //   text={labels.es.sector}
            //   setData={setSectorSelected}
            //   data={sectorSelected}
            //   options={sectors
            //     .filter((elm: any) => !elm.deleted)
            //     .sort((a, b) => a.name.localeCompare(b.name))
            //     .map((elm: any) => {
            //       return { name: elm.name, id: elm.id }
            //     })}
            // />
          )}

        {allCompanyStores.length > 0 &&
          !(role.id === 'role1' || role.id === 'role3' || role.id === 'role2') &&
          ['role3', 'role1', 'role5'].includes(userData.role.id) && (
            <SelectDialog
              required
              text={labels.es.store}
              setData={setStore}
              data={store}
              options={
                allCompanyStores &&
                allCompanyStores
                  .filter((elm: any) => !elm.deleted)
                  .sort((a, b) => a.storeName.localeCompare(b.storeName))
                  .map((elm: any) => {
                    return { name: elm.storeName, id: elm.id }
                  })
              }
            />
          )}
        {/* {configCompanyAdmin &&
          configCompanyAdmin.length > 0 &&
          userData.role.id === 'role1' &&
          !(['role1', 'role3', 'role2', 'role4'].includes(role) || ['role1', 'role3', 'role2', 'role4'].includes(role.id)) && (
            <>
              <SelectDialog
                required
                text={labels.es.sectors}
                setData={setSectorSelected}
                data={sectorSelected}
                options={configCompanyAdmin
                  ?.filter((elm: any) => !elm.deleted)
                  .sort((a: any, b: any) => a.name.localeCompare(b.name))
                  .map((elm: any) => {
                    return { name: elm.nam1e, id: elm.id }
                  })}
              />
            </>
          )} */}
        {role && ['role3', 'role4', 'role5', 'role6'].includes(role.id) && (
          <TextInput
            required
            value={values.employeeId}
            handleChange={(e) => {
              handleChange(e)
              // validateEmployeeId(e)
            }}
            name="employeeId"
            text={labels.es.employeeId}
            errorBadge={errorEmployeeId}
            placeholder={labels.es.employeeId}
          />
        )}
        {role && ['role2', 'role3', 'role4', 'role5', 'role6'].includes(role.id) && (
          <TextInput
            required
            value={values.workingSince}
            handleChange={(e) => handleChange(e)}
            name="workingSince"
            id="workingSince"
            text={labels.es.workingSince}
            placeholder={labels.es.workingSince}
            type="date"
          />
        )}
      </Box>
    </Modal>
  )
}
