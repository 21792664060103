import React, { useContext, useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { algoliasearch } from 'algoliasearch'
import { Box, IconButton, Stack, useTheme } from '@mui/material'
import { deleteSwalConfig } from 'src/utils/swal.utils'
import SearchInput from 'src/components/SearchInput'
import UsersAddEdit from './UsersAddEdit'
import TableAlg from 'src/components/Table/TableAlg'
import deleteElements from 'src/services/deleteElements'
import { HeaderContext } from 'src/context/header/headerContext'
import { AlertContext } from 'src/context/alert/alertContext'
import { AuthContext } from 'src/context/auth/authContext'
import { labels } from 'src/labels/main_labels'
import { deleteUser } from 'src/services/users.services'
import { AddButton } from 'src/components/Buttons/AddButton'
import SimpleSelect from 'src/components/Form/Select'
import { InfoContext } from 'src/context/info/infoContext'
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff'
import { useHistory } from 'react-router-dom'
import Modal from 'src/components/Modals/Modal'
import FooterForm from 'src/components/Form/FooterForm'
import TextInput from 'src/components/Form/TextInput'
const headCellsAdmin: any[] = [
  {
    idField: 'name',
    align: 'left',
    disablePadding: true,
    label: labels.es.name,
    order: true,
    fieldtype: 'name'
  },
  {
    idField: 'lastname',
    align: 'left',
    disablePadding: true,
    label: labels.es.lastnames,
    order: true,
    fieldtype: 'lastnames'
  },
  {
    idField: 'company',
    align: 'left',
    disablePadding: true,
    label: labels.es.company,
    order: true,
    fieldtype: 'string'
  },
  {
    idField: 'store',
    align: 'left',
    disablePadding: true,
    label: labels.es.store,
    order: true,
    fieldtype: 'string'
  },
  {
    idField: 'role',
    align: 'left',
    disablePadding: true,
    label: labels.es.positionJob,
    order: true,
    fieldtype: 'string'
  },
  {
    idField: 'sector',
    align: 'left',
    disablePadding: true,
    label: labels.es.sector,
    order: true,
    fieldtype: 'sectors'
  },
  {
    idField: 'employeeId',
    align: 'left',
    disablePadding: true,
    label: labels.es.employeeId,
    order: true,
    fieldtype: 'string'
  },
  {
    idField: 'email',
    align: 'center',
    disablePadding: false,
    label: labels.es.email,
    fieldtype: 'string'
  },
  {
    idField: 'workingSince',
    align: 'center',
    disablePadding: true,
    label: labels.es.workingSince,
    order: true,
    fieldtype: 'date-time'
  },
  {
    idField: 'createdAt',
    align: 'center',
    disablePadding: true,
    label: labels.es.registerDate,
    order: true,
    fieldtype: 'date-time'
  }
]

const headCellsUsers: any[] = [
  {
    idField: 'name',
    align: 'left',
    disablePadding: true,
    label: labels.es.name,
    order: true,
    fieldtype: 'name'
  },
  {
    idField: 'lastname',
    align: 'left',
    disablePadding: true,
    label: labels.es.lastnames,
    order: true,
    fieldtype: 'lastnames'
  },
  {
    idField: 'role',
    align: 'left',
    disablePadding: true,
    label: labels.es.positionJob,
    order: true,
    fieldtype: 'string'
  },
  {
    idField: 'sector',
    align: 'left',
    disablePadding: true,
    label: labels.es.sector,
    order: true,
    fieldtype: 'sectors'
  },
  {
    idField: 'employeeId',
    align: 'left',
    disablePadding: true,
    label: labels.es.employeeId,
    order: true,
    fieldtype: 'string'
  },
  {
    idField: 'email',
    align: 'center',
    disablePadding: false,
    label: labels.es.email,
    fieldtype: 'string'
  },
  {
    idField: 'workingSince',
    align: 'center',
    disablePadding: true,
    label: labels.es.workingSince,
    order: true,
    fieldtype: 'date-time'
  },
  {
    idField: 'createdAt',
    align: 'center',
    disablePadding: true,
    label: labels.es.registerDate,
    order: true,
    fieldtype: 'date-time'
  }
]

const headCellsHr: any[] = [
  {
    idField: 'name',
    align: 'left',
    disablePadding: true,
    label: labels.es.name,
    order: true,
    fieldtype: 'name'
  },
  {
    idField: 'lastname',
    align: 'left',
    disablePadding: true,
    label: labels.es.lastnames,
    order: true,
    fieldtype: 'lastnames'
  },
  {
    idField: 'store',
    align: 'left',
    disablePadding: true,
    label: labels.es.store,
    order: true,
    fieldtype: 'string'
  },
  {
    idField: 'role',
    align: 'left',
    disablePadding: true,
    label: labels.es.positionJob,
    order: true,
    fieldtype: 'string'
  },
  {
    idField: 'sector',
    align: 'left',
    disablePadding: true,
    label: labels.es.sector,
    order: true,
    fieldtype: 'sectors'
  },
  {
    idField: 'employeeId',
    align: 'left',
    disablePadding: true,
    label: labels.es.employeeId,
    order: true,
    fieldtype: 'string'
  },
  {
    idField: 'email',
    align: 'center',
    disablePadding: false,
    label: labels.es.email,
    fieldtype: 'string'
  },
  {
    idField: 'workingSince',
    align: 'center',
    disablePadding: true,
    label: labels.es.workingSince,
    order: true,
    fieldtype: 'date-time'
  },
  {
    idField: 'createdAt',
    align: 'center',
    disablePadding: true,
    label: labels.es.registerDate,
    order: true,
    fieldtype: 'date-time'
  }
]

export default function Users(): JSX.Element {
  const { userData, firebase } = useContext(AuthContext)
  const theme = useTheme()
  const history = useHistory()
  const { showHeader } = useContext(HeaderContext)
  const { showSnackbar } = useContext(AlertContext)
  const { allCompanies, allStores, roles, fetchCompanyConfigData } = useContext(InfoContext)
  const [data, setData] = useState<any | null>([])
  const [editData, setEditData] = useState<any | null>(null)
  const [initHeader, setInitHeader] = useState(true)
  const [loadingTable, setLoadingTable] = useState(false)
  const [selected, setSelected] = useState<string[]>([])
  const [filter, setFilter] = useState<string>('')
  const [filterCompany, setFilterCompany] = useState<string>('')
  const [filterStore, setFilterStore] = useState<string>('')
  const [openModal, setOpenModal] = useState(false)
  const [filterStoreOptions, setFilterStoreOptions] = useState<any[] | never>([])
  const [searchString, setSearchString] = useState('')
  const [userSelectedChangeEmail, setUserSelectedChangeEmail] = useState<any | null>(null)
  const [changeEmailModal, setChangeEmailModal] = useState(false)
  const [newEmail, setNewEmail] = useState('')
  const [loadingNewEmail, setLoadingNewEmail] = useState(false)
  const [totalPages, setTotalPages] = useState(1)
  const [totalHits, setTotalHits] = useState(0)
  const [page, setPage] = useState(0)
  const [state, setState] = useState<any>({
    pagesData: {}
  })
  // ALGOLIA

  const APPLICATION_ID = '6T6D6HZUIF'
  const SEARCH_API_KEY = 'e52785ab0e3733d83a4618b38a44d65c'

  // const client = searchClient('67B0YCU80N', '48bdf0a75a92a6b7f06e7c737e77a43b')
  const client = algoliasearch('67B0YCU80N', '48bdf0a75a92a6b7f06e7c737e77a43b')

  const algoliaSearchFn = async (indexName: string, pagina: number, query: string, filters?: string[]): Promise<any> => {
    try {
      console.log(filters, page, query)
      const response = await client.searchSingleIndex({
        indexName,
        searchParams: {
          query,
          facetFilters: filters,
          page: page
        }
      })
      console.log(response)
      // setData(response.hits)
      setTotalPages(response?.nbPages || 1)
      setTotalHits(response.nbHits || 1)
      const newPagesData = { ...state.pagesData }

      newPagesData[pagina] = response.hits
      setData(data.concat(response.hits))
      setState({
        ...state,
        pagesData: newPagesData
      })
    } catch (error) {
      console.error('Error fetching data from Algolia:', error)
      return null
    }
  }

  // PAGINATION

  // TS PAGINAS

  interface PageData {
    [key: number]: any[]
  }
  interface State {
    totalCount: number
    lastVisible: any
    pagesData: PageData
  }

  const fetchData = async (pag?: number, cleanSearch?: boolean) => {
    const pagina = pag || page
    if (loadingTable) return

    try {
      if (!state.pagesData[pagina]) {
        console.log(filterCompany, filterStore, filter)
        setLoadingTable(true)
        const filters = []
        if (filterCompany) {
          filters.push(`companyId:${filterCompany}`)
        }
        if (filterStore) {
          filters.push(`storeId:${filterStore}`)
        }
        if (filter) {
          filters.push(`role.name:${filter}`)
        }
        if (userData.role.id === 'role3' || userData.role.id === '') {
          filters.push(`companyId:${filterCompany}`)
        }

        if (['role4', 'role5', 'role6'].includes(userData.role.id)) {
          filters.push(`storeId:${userData?.storeId}`)
          filters.push(`companyId:${userData?.companyId}`)
        }
        filters.push('deleted:false')
        algoliaSearchFn('metaphysio_users', pagina, searchString, filters)
      }
      // if (loadingTable) return
    } catch (error) {
      console.log(error)
      if (error instanceof Error) showSnackbar('error', error.message)
    } finally {
      setLoadingTable(false)
    }
  }
  const refreshSearch = async () => {
    if (filter || filterCompany || filterStore || searchString || searchString === '') {
      setData([])
      setPage(0)
      setState({ pagesData: {} })
    }
  }

  useEffect(() => {
    if (!data.length && (filter || filterCompany || filterStore || searchString)) {
      fetchData(0)
      setPage(0)
    }
  }, [data])

  useEffect(() => {
    refreshSearch()
  }, [filter, filterCompany, filterStore, searchString])

  const closeModal = () => {
    setOpenModal(false)
    setEditData(null)
  }

  const handleSearch = (str: string) => {
    if (str === '') {
      refreshSearch()
      setSearchString(str)
      fetchData(1, true)
    } else {
      setSearchString(str)
    }
  }

  const Header = () => {
    return (
      <Stack direction="row" justifyContent="space-between">
        <SearchInput placeholder={labels.es.searchUser} name="search" handleSubmit={handleSearch} clean fullwidth />
        <AddButton onClick={() => setOpenModal(true)} text={labels.es.addUser} />
      </Stack>
    )
  }

  const onChangeEmail = async (data: Record<string, any>) => {
    setUserSelectedChangeEmail(data.id)
    setChangeEmailModal(true)
  }

  const sendEmailChangeRequest = async () => {
    setLoadingNewEmail(true)
    const changeUserEmailFn = firebase.functions.httpsCallable('changeUserEmail')

    try {
      await changeUserEmailFn({ id: userSelectedChangeEmail, email: newEmail })
      await firebase.db.collection('users').doc(userSelectedChangeEmail).update({ email: newEmail })

      setUserSelectedChangeEmail(null)
      setNewEmail('')
      setLoadingNewEmail(false)
      setChangeEmailModal(false)
      setData([])
      setState({ pagesData: {} })

      setTimeout(() => {
        fetchData()
      }, 1000)
      showSnackbar('success', 'Email actualizado correctamente')
    } catch (error) {
      setNewEmail('')
      setUserSelectedChangeEmail(null)
      setLoadingNewEmail(false)
      setChangeEmailModal(false)
      showSnackbar('error', 'Hubo un error mientras se intentaba actualizar el email')
    }
  }

  useEffect(() => {
    if (userData && userData.role.id) fetchData()
    if (userData.role.id === 'role3' && userData.companyId) setFilterCompany(userData.companyId)
  }, [page])

  useEffect(() => {
    filterCompany && fetchCompanyConfigData(filterCompany)
  }, [filterCompany])

  useEffect(() => {
    // if (!initHeader) return
    showHeader(Header)
  }, [])

  const onDelete = async (data: any) => {
    Swal.fire(deleteSwalConfig(labels.es.Users, theme.palette.primary.main)).then((result) => {
      if (result.isConfirmed) {
        deleteElements(data, deleteUser)
          .then(() => {
            showSnackbar('success', labels.es.userDeleted)
            fetchData()
            closeModal()
            setSelected([])
          })
          .catch((error) => {
            if (error instanceof Error) showSnackbar('error', error.message)
          })
      }
    })
  }

  const onEdit = async (data: Record<string, any>) => {
    setEditData(data)
    setOpenModal(true)
  }

  const filterOptions = [
    ...roles.map((elm) => ({
      value: elm.name,
      label: elm.name
    })),
    { value: '', label: labels.es.filterByRole }
  ]

  const filterCompanyOptions = [{ id: '', name: labels.es.filterByCompany }, ...allCompanies].map((elm) => ({
    value: elm.id,
    label: elm.name
  }))

  useEffect(() => {
    setFilterStore('')

    const loadfilterStoreOptions = allStores
      .filter((elm) => elm.companyId === filterCompany)
      .map((elm) => ({
        value: elm.id,
        label: elm.storeName
      }))

    setFilterStoreOptions([{ value: '', label: labels.es.filterByStore }, ...loadfilterStoreOptions])
  }, [filterCompany])

  const cleanFilters = () => {
    console.log('limpiafiltros')
    setData([])
    setState({ pagesData: {} })

    setPage(0)
    setFilter('')
    setFilterCompany('')
    setFilterStore('')
    setSearchString('')
  }

  const onRowClick = (row: Record<string, any>) => {
    history.push({ pathname: `/usuarios/${row.id}`, state: { name: row?.name + ' ' + row?.lastname } })
  }

  return (
    <>
      <TableAlg
        noCheckbox={true}
        headCells={userData.role.id === 'role1' ? headCellsAdmin : userData.role.id === 'role3' ? headCellsHr : headCellsUsers}
        rows={data.filter((row: any) => row.deleted !== true)}
        snackBar
        onDelete={onDelete}
        onChangeEmail={onChangeEmail}
        onEdit={onEdit}
        loading={loadingTable}
        page={page}
        setPage={setPage}
        totalHits={totalHits}
        selected={selected}
        setSelected={setSelected}
        onRowClick={onRowClick}
        filters={
          userData.role.id === 'role1' ? (
            <>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  '> div': { ml: 2, my: theme.breakpoints.down('sm') ? 0.5 : 0 },
                  [theme.breakpoints.down('sm')]: {
                    overflowX: 'scroll'
                  }
                }}
              >
                <SimpleSelect
                  minWidth={200}
                  name="filterCompany"
                  data={filterCompany}
                  handleChange={(e) => setFilterCompany(e.target.value)}
                  options={filterCompanyOptions}
                />

                {filterStoreOptions && filterCompany && (
                  <SimpleSelect
                    minWidth={200}
                    name="filterStore"
                    data={filterStore}
                    handleChange={(e) => setFilterStore(e.target.value)}
                    options={filterStoreOptions}
                  />
                )}
                {filterCompany && (
                  <SimpleSelect
                    minWidth={200}
                    name="filters"
                    data={filter}
                    handleChange={(e) => setFilter(e.target.value)}
                    options={filterOptions}
                  />
                )}
                <IconButton sx={{ mx: 2 }} color="primary" onClick={cleanFilters}>
                  <FilterAltOffIcon />
                </IconButton>
              </Box>
            </>
          ) : userData.role.id === 'role3' ? (
            <>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  '> div': { ml: 2, my: theme.breakpoints.down('sm') ? 0.5 : 0 },
                  [theme.breakpoints.down('sm')]: {
                    overflowX: 'scroll'
                  }
                }}
              >
                {filterStoreOptions && (
                  <>
                    <SimpleSelect
                      minWidth={200}
                      name="filterStore"
                      data={filterStore}
                      handleChange={(e) => setFilterStore(e.target.value)}
                      options={filterStoreOptions}
                    />
                    <IconButton sx={{ mx: 2 }} color="primary" onClick={cleanFilters}>
                      <FilterAltOffIcon />
                    </IconButton>
                  </>
                )}
                <SimpleSelect
                  minWidth={200}
                  name="filters"
                  data={filter}
                  handleChange={(e) => setFilter(e.target.value)}
                  options={filterOptions}
                />
              </Box>
            </>
          ) : (
            <></>
          )
        }
      />
      <UsersAddEdit openModal={openModal} closeModal={closeModal} dataToEdit={editData} onDelete={onDelete} fetchData={fetchData} />
      <Modal
        title={'Cambiar email'}
        open={changeEmailModal}
        handleClose={() => setChangeEmailModal(false)}
        footer={
          <FooterForm
            mainText
            text={labels.es.send}
            editing={false}
            closeModal={() => setChangeEmailModal(false)}
            onSave={sendEmailChangeRequest}
            loading={loadingNewEmail}
            disabled={!newEmail}
          />
        }
      >
        <TextInput
          noTopBorder
          value={newEmail}
          handleChange={(e) => setNewEmail(e.target.value)}
          name="email"
          text={labels.es.newEmail}
          placeholder={labels.es.newEmail}
          rows={1}
        />
      </Modal>
    </>
  )
}
